import axios from 'axios';

export const setAxiosToken = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = token;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

export const getAxiosError = (error) => {
    if (error.response) {
        return error.response.data.error;
    } else if (error.request) {
        console.log("REQUEST", error.request);
        return "CONNECTION_REFUSED";
    } else {
        console.log('Error', error.message);
        return "CONNECTION_REFUSED";
    }
};

