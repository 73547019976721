import axios from "axios";
import { setAxiosToken, getAxiosError } from "../../utils/axiosUtils";
import * as actionTypes from "./actionTypes";

export const authReset = () => {
    return {
        type: actionTypes.AUTH_RESET
    };
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authValidate = () => {
    return {
        type: actionTypes.AUTH_VALIDATE
    };
};

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        payload: error
    };
};

export const authSetRedirectPath = path => {
    return {
        type: actionTypes.AUTH_SET_REDIRECT_PATH,
        payload: path
    };
};

export const authSetUser = (user) => {
    return {
        type: actionTypes.AUTH_SET_USER,
        payload: user
    };
};

export const logout = () => {
    localStorage.removeItem("atk");
    setAxiosToken(false);

    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const signIn = (email, password) => {
    return dispatch => {
        dispatch(authValidate());

        const payload = {
            email: email,
            password: password
        };

        axios.post(process.env.REACT_APP_API_URI + "/auth/login", payload)
            .then(response => {
                var token = response.headers["authorization"];
                dispatch(authUpdateToken(token));
            })
            .catch(error => {
                dispatch(authFail(getAxiosError(error)));
            });
    };
};

export const signInGoogle = (accessToken) => {
    return dispatch => {
        dispatch(authStart());

        axios.post(process.env.REACT_APP_API_URI + "/auth/google", { access_token: accessToken })
            .then(response => {
                var token = response.headers["authorization"];
                dispatch(authUpdateToken(token));
            })
            .catch(error => {
                dispatch(authFail(getAxiosError(error)));
            });
    };
};

export const signInFacebook = (accessToken) => {
    return dispatch => {
        dispatch(authStart());

        axios.post(process.env.REACT_APP_API_URI + "/auth/facebook", { access_token: accessToken })
            .then(response => {
                var token = response.headers["authorization"];
                dispatch(authUpdateToken(token));
            })
            .catch(error => {
                dispatch(authFail(getAxiosError(error)));
            });
    };
};

export const signUp = (name, email, password) => {
    return dispatch => {
        dispatch(authValidate());

        const payload = {
            name: name,
            email: email,
            password: password
        };

        axios.post(process.env.REACT_APP_API_URI + "/auth/register", payload)
            .then(response => {
                var token = response.headers["authorization"];
                dispatch(authUpdateToken(token));
            })
            .catch(error => {
                dispatch(authFail(getAxiosError(error)));
            });
    };
};

export const confirmAccount = (user, challenge, history) => {
    return dispatch => {
        dispatch(authStart());

        const payload = {
            user: user,
            challenge: challenge
        };

        axios.post(process.env.REACT_APP_API_URI + "/auth/confirm", payload)
            .then(response => {
                history.push("/signin");
                dispatch(authUpdateUser());
            })
            .catch(error => {
                dispatch(authFail(getAxiosError(error)));
            });
    };
};

export const authUpdateUser = () => {
    return dispatch => {
        axios.post(process.env.REACT_APP_API_URI + "/auth/current")
            .then(response => {
                dispatch(authSetUser(response.data));
            })
            .catch(error => {
                dispatch(authFail(getAxiosError(error)));
            });
    };
};

export const authUpdateUserRedirect = (history, path) => {
    return dispatch => {
        axios.post(process.env.REACT_APP_API_URI + "/auth/current")
            .then(response => {
                dispatch(authSetUser(response.data));
                history.push(path);
            })
            .catch(error => {
                dispatch(authFail(getAxiosError(error)));
            });
    };
};

export const authUpdateToken = (token) => {
    localStorage.setItem("atk", token);
    setAxiosToken(token);

    return dispatch => {
        axios.post(process.env.REACT_APP_API_URI + "/auth/current")
            .then(response => {
                dispatch(authSetUser(response.data));
            })
            .catch(error => {
                dispatch(authFail(getAxiosError(error)));
            });
    };
};
