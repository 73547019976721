import { addLocaleData } from 'react-intl';
import enLang from './entries/en-US';
//import esLang from './entries/es-ES';
import ptLang from './entries/pt-BR';

const AppLocale = {
    "en": enLang,
    "en-US": enLang,
    "en-GB": enLang,
    // "es": esLang,
    // "es-ES": esLang,
    "pt": ptLang,
    "pt-BR": ptLang,
    "pt-PT": ptLang,
};
addLocaleData(AppLocale.en.data);
//addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.pt.data);

export default AppLocale;
