import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { injectIntl } from 'react-intl';

import * as actions from "../../store/actions/index";

import { Button, Row, Col, Container, Form, FormGroup, Label, Card } from "reactstrap";
import TextFieldGroup from '../../components/common/TextFieldGroup';
// import { Colxx } from "../../components/customBootstrap";

class Auth extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fullname: "",
            email: "",
            password: "",
            captcha: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.body.classList.add("background");
        this.props.onAuthReset();
    }

    componentWillUnmount() {
        document.body.classList.remove("background");
    }

    handleChange = async event => {
        const { target } = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [name]: value
        });
    };

    submitHandler = event => {
        event.preventDefault();
        this.props.onSignup(this.state.fullname, this.state.email, this.state.password);
    };

    render() {
        const { intl } = this.props;

        let error_name = null;
        let error_email = null;
        let error_password = null;

        if (this.props.error) {
            error_name = this.props.error.name;
            error_email = this.props.error.email;
            error_password = this.props.error.password;
        }

        return (
            <Fragment>
                <div className="fixed-background bg-primary-light" />
                <main>
                    <Container>
                        <Row className="h-100">
                            <Col xs="12" md="10" className="mx-auto my-auto">
                                <Card className="auth-card">
                                    <div className="position-relative image-side bg-info-light">
                                        <p className="text-white h2 mb-3">{intl.formatMessage({ id: 'view.signup.text.title' })}</p>
                                        <p className="white">
                                            {intl.formatMessage({ id: 'view.signup.text.1' })}<br />
                                            {intl.formatMessage({ id: 'view.signup.text.2.prefix' })}{" "}
                                            <NavLink to={'/signin'} className="white"><b>{intl.formatMessage({ id: 'view.signup.text.2.link' })}</b></NavLink>
                                            {intl.formatMessage({ id: 'view.signup.text.2.suffix' })}
                                        </p>
                                    </div>
                                    <div className="form-side">
                                        <div className="pt-2">
                                            <Form className="form" role="form" onSubmit={this.submitHandler} >
                                                <FormGroup className="mb-3">
                                                    <Label className="form-control-label" htmlFor="input-fullname">{intl.formatMessage({ id: 'view.signup.lb.name' })}</Label>
                                                    <TextFieldGroup
                                                        id="input-fullname"
                                                        type="text"
                                                        placeholder={intl.formatMessage({ id: 'view.signup.ph.name' })}
                                                        name="fullname"
                                                        className="form-control form-control-alternative"
                                                        value={this.state.fullname}
                                                        onChange={this.handleChange}
                                                        error={error_name}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="form-control-label" htmlFor="input-email">{intl.formatMessage({ id: 'view.signup.lb.email' })}</Label>
                                                    <TextFieldGroup
                                                        id="input-email"
                                                        type="email"
                                                        placeholder={intl.formatMessage({ id: 'view.signup.ph.email' })}
                                                        name="email"
                                                        className="form-control form-control-alternative"
                                                        value={this.state.email}
                                                        onChange={this.handleChange}
                                                        error={error_email}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="form-control-label" htmlFor="input-password">{intl.formatMessage({ id: 'view.signup.lb.password' })}</Label>
                                                    <TextFieldGroup
                                                        id="input-password"
                                                        type="password"
                                                        placeholder={intl.formatMessage({ id: 'view.signup.ph.password' })}
                                                        name="password"
                                                        className="form-control form-control-alternative"
                                                        value={this.state.password}
                                                        onChange={this.handleChange}
                                                        error={error_password}
                                                    />
                                                </FormGroup>
                                                <div className="text-center">
                                                    <Button color="info" className="btn-lg my-4 mt-5 py-3 btn-block">{this.props.loading ? intl.formatMessage({ id: 'view.signup.button.loading' }) : intl.formatMessage({ id: 'view.signup.button' })}</Button>
                                                    <p className="text-muted"><small>
                                                        {intl.formatMessage({ id: 'view.signup.terms.1.prefix' })}
                                                        <NavLink to="/terms" className="text-link"><b>{intl.formatMessage({ id: 'view.signup.terms.1.link' })}</b></NavLink>
                                                        {intl.formatMessage({ id: 'view.signup.terms.1.suffix' })}{" "}
                                                        {intl.formatMessage({ id: 'view.signup.terms.2' })}
                                                    </small></p>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </main>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.validating,
        error: state.auth.error,
        isAuthenticated: state.auth.isAuthenticated,
        authRedirectPath: state.auth.authRedirectPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignup: (fullname, email, password) => dispatch(actions.signUp(fullname, email, password)),
        onAuthReset: () => dispatch(actions.authReset())
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Auth));
