import * as actionTypes from '../actions/actionTypes';
import { updateObject, isEmpty } from '../../utils/utility';

const initialState = {
    isAuthenticated: false,
    user: {},
    error: null,
    loading: false,
    validating: false,
    redirectPath: '/'
};

const authReset = (state, action) => {
    return updateObject(state, {
        error: null,
        validating: false
    });
};

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
};

const authValidate = (state, action) => {
    return updateObject(state, {
        validating: true
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        isAuthenticated: false,
        error: action.payload,
        loading: false,
        validating: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        isAuthenticated: false,
        error: null,
        loading: false,
        validating: false
    });
};

const authSetRedirectPath = (state, action) => {
    return updateObject(state, {
        authRedirectPath: action.payload
    })
};

const authSetUser = (state, action) => {
    return updateObject(state, {
        user: action.payload,
        isAuthenticated: !isEmpty(action.payload),
        error: null,
        loading: false,
        validating: false
    })
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_RESET:
            return authReset(state, action);
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_VALIDATE:
            return authValidate(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        case actionTypes.AUTH_SET_REDIRECT_PATH:
            return authSetRedirectPath(state, action);
        case actionTypes.AUTH_SET_USER:
            return authSetUser(state, action);
        default:
            return state;
    }
};

export default reducer;