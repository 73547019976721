import React from "react";
import { connect, getIn } from "formik";

import { Select, Form } from 'antd';
const Option = Select.Option;
const FormItem = Form.Item;

class MySelect extends React.Component {
    handleChange = value => {
        this.props.onChange(this.props.name, value);
    };

    handleBlur = () => {
        this.props.onBlur(this.props.name, true);
    };

    render() {
        const error = getIn(this.props.formik.errors, this.props.name);
        const touched = getIn(this.props.formik.touched, this.props.name);

        return (
            <React.Fragment>
                <FormItem
                    className="mb-0"
                    hasFeedback={false}
                    validateStatus={touched && error ? "error" : ""}
                    help={touched && error ? error : ""}
                >
                    <Select
                        {...this.props}
                        showSearch
                        optionFilterProp="children"
                        notFoundContent="Não Encontrado" showAction={['focus', 'click']}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur} >
                        {
                            this.props.options.map(function (opt) {
                                return (
                                    <Option
                                        key={opt.value}
                                        value={opt.value}>
                                        {opt.label}
                                    </Option>
                                );
                            })
                        }
                    </Select>
                </FormItem>
            </React.Fragment>
        );
    }
}

export default connect(MySelect);