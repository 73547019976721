module.exports = {
    /* 01.General */
    "general.copyright": "VHMS © 2018 All Rights Reserved.",
    "general.name.short": "VHMS",
    "general.name.long": "Virtual Health Medical Solutions",

    /* 02.User Login, Logout, Register */
    "user.login-title": "Login",
    "user.register": "Register",
    "user.forgot-password": "Forgot Password",
    "user.email": "E-mail",
    "user.password": "Password",
    "user.forgot-password-question": "Forget password?",
    "user.fullname": "Full Name",
    "user.login-button": "LOGIN",
    "user.register-button": "REGISTER",
    "user.reset-password-button": "RESET",

    /* 03.Menu */
    "menu.app": "Home",
    "menu.gogo": "Home",
    "menu.start": "Start",
    "menu.second-menu": "Second Menu",
    "menu.second": "Second",
    "menu.ui": "UI",
    "menu.charts": "Charts",
    "menu.chat": "Chat",
    "menu.survey": "Survey",
    "menu.todo": "Todo",

    /* 04.Error Page */
    "layouts.error-title": "Ooops... looks like an error occurred!",
    "layouts.error-code": "Error code",
    "layouts.go-back-home": "GO BACK HOME",

    /* 02.Sidebar */
    "sidebar.home": "Início",
    "sidebar.exams": "Exames",
    "sidebar.exams.home": "Meus Exames",
    "sidebar.diagnostics": "Diagnósticos",
    "sidebar.diagnostics.home": "Meus Diagnósticos",

    /* 03.Navbar */
    "navbar.why": "Porquê VHMS?",
    "navbar.services": "Serviços",
    "navbar.pricing": "Preços",
    "navbar.search": "Pesquisar",
    "navbar.logout": "Sair",
    "navbar.settings": "Configurações",
    "navbar.change.account": "Mude a sua Conta",

    /* 04.Footer */
    "footer.blog": "Blog",
    "footer.privacy": "Privacidade",
    "footer.cookies": "Cookies",
    "footer.about": "Sobre Nós",

    /* VIEW: Home */
    "home.title": "Início",
    "home.my.records": "Meus Registros",
    "home.my.feed": "Meu Feed",
    "home.my.health": "Minha Saúde",
    "home.my.calendar": "Meu Calendário",
    "home.my.lifecard": "Meu Cartão-Vida",
    "home.my.lifecard.click": "Clique aqui para ver o seu Cartão",

    "home.my.records.areas": "Sistemas do Corpo Humano",
    "home.my.records.areas.general": "Geral",
    "home.my.records.areas.circulatory": "Circulatório",
    "home.my.records.areas.digestive": "Digestivo",
    "home.my.records.areas.endocrine": "Endócrino",
    "home.my.records.areas.exocrine": "Exócrino",
    "home.my.records.areas.excretory": "Excretor",
    "home.my.records.areas.lymphatic": "Linfático",
    "home.my.records.areas.muscular": "Muscular",
    "home.my.records.areas.nervous": "Nervoso",
    "home.my.records.areas.reprodutive": "Reprodutivo",
    "home.my.records.areas.respiratory": "Respiratório",
    "home.my.records.areas.skeletal": "Esquelético",

    /* VIEW: signin */
    "view.signin.title": "Bem-vindo!",
    "view.signin.text.line.1": "Por favor, utilize as suas credenciais para entrar.",
    "view.signin.text.line.2.prefix": "Se você não é um menbro, por favor",
    "view.signin.text.line.2.link": "registre-se",
    "view.signin.text.line.2.suffix": ".",
    "view.signin.text.social": "Entre utilizando",
    "view.signin.text.local": "Ou com o seu email",
    "view.signin.email": "Email",
    "view.signin.password": "Senha",
    "view.signin.button": "Entrar",
    "view.signin.loading": "Carregando...",
    "view.signin.ph.email": "seunome@exemplo.com",
    "view.signin.ph.password": "Sua senha...",

    /* VIEW: signup */
    "view.signup.text.title": "Bem-vindo!",
    "view.signup.text.1": "Por favor, preencha o formulário para criar o seu perfil.",
    "view.signup.text.2.prefix": "Se você já é um membro, ",
    "view.signup.text.2.link": "clique aqui",
    "view.signup.text.2.suffix": ".",
    "view.signup.lb.name": "Nome Completo",
    "view.signup.lb.email": "Email",
    "view.signup.lb.password": "Senha",
    "view.signup.ph.name": "Seu Nome",
    "view.signup.ph.email": "seunome@exemplo.com",
    "view.signup.ph.password": "Crie a sua senha",
    "view.signup.button": "Registre-se no VHMS",
    "view.signup.button.loading": "Carregando...",
    "view.signup.terms.1.prefix": "Ao clicar em \"Registre-se no VHMS\", você concorda com nossos ",
    "view.signup.terms.1.link": "termos de serviço e declaração de privacidade",
    "view.signup.terms.1.suffix": ".",
    "view.signup.terms.2": "Ocasionalmente, enviaremos emails relacionados à sua conta.",

    /* VIEW: Confirm Account */
    "view.confirm.title": "Verifique a sua Conta",
    "view.confirm.logged.text": "Enviamos um email para {email} com um link para confirmar a sua conta. Por favor, verifique seu email para confirmar a sua conta. Se você não recebey este email, clique no link abaixo:",
    "view.confirm.logged.button": "Reenviar Email",
    "view.confirm.not.logged.text": "Enviamos um email para você com um link para confirmar sua conta. Por favor, verifique seu email para confirmar sua conta. Se você não recebeu este e-mail, faça login no link abaixo: ",
    "view.confirm.not.logged.button": "Entrar",

    /* VIEW: Profile */
    "view.profile.title": "Meu Perfil",
    "view.profile.user.title": "Informações do Usuário",
    "view.profile.contact.title": "Informações de Contato",
    "view.profile.work.title": "Informações de Trabalho",

    "view.profile.button.save": "Salvar",
    "view.profile.button.jump": "Pular Etapa",
    "view.profile.button.loading": "Carregando...",

    "view.profile.name": "Nome",
    "view.profile.name.ph": "Digite o seu nome...",
    "view.profile.birthday": "Data de Nascimento",
    "view.profile.birthday.ph": "Selecione sua Data de Nascimento...",

    "view.profile.gender": "Gênero",
    "view.profile.gender.ph": "Selecione seu gênero...",
    "view.profile.gender.male": "Masculino",
    "view.profile.gender.female": "Feminimo",

    "view.profile.civil": "Estado Civil",
    "view.profile.civil.ph": "Selecione seu Estado...",
    "view.profile.civil.single": "Solteiro(a)",
    "view.profile.civil.married": "Casado(a)",
    "view.profile.civil.widowed": "Viúvo(a)",
    "view.profile.civil.divorced": "Divorciado(a)",
    "view.profile.civil.separated": "Separado(a)",

    "view.profile.address": "Endereço",
    "view.profile.address.ph": "Digite seu Endereço...",
    "view.profile.city": "Cidade",
    "view.profile.city.ph": "Digite sua Cidade...",
    "view.profile.country": "País",
    "view.profile.country.ph": "Digite seu País...",
    "view.profile.postal": "CEP",
    "view.profile.postal.ph": "Digite seu CEP...",

    "view.profile.occupation": "Ocupação",
    "view.profile.occupation.ph": "Digite sua Ocupação...",

    "view.profile.occupation.length": "Tempo de Ocupação",
    "view.profile.occupation.length.ph": "Selecione o Tempo de Ocupação...",
    "view.profile.workp": "Padrão de Trabalho",
    "view.profile.workp.ph": "Selecione seu Padrão de Trabalho...",
};
