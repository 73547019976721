/* AUHT*/
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_START = 'AUTH_START';
export const AUTH_VALIDATE = 'AUTH_VALIDATE';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_USER = 'SET_AUTH_USER';
export const AUTH_SET_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';

/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
