import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import jwt_decode from "jwt-decode";

import App from './App';
import { configureStore } from './store/store';
import { authStart, authUpdateToken, logout } from "./store/actions/index";
import * as serviceWorker from './serviceWorker';

// REDUX
const store = configureStore();

// Check for existing token
if (localStorage.atk) {
    store.dispatch(authStart());
    var token = localStorage.atk;
    var decoded = jwt_decode(token);
    var currentTime = Date.now() / 1000;

    if (decoded.exp > currentTime) {
        store.dispatch(authUpdateToken(localStorage.atk));
        // store.dispatch(authSetUser(decoded));
        // store.dispatch(authUpdateUser());
    } else {
        store.dispatch(logout());
    }
}

// Declare APP
const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
