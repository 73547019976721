import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import detectBrowserLanguage from 'detect-browser-language';
import { IntlProvider } from "react-intl";

import AppLocale from "./lang/index";
import { changeLocale } from "./store/actions/index";

// STYLES
import './assets/icons/simple-line-icons/css/simple-line-icons.css';
import './assets/icons/iconsmind/style.css';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";

import 'bootstrap/dist/css/bootstrap.css';
//import './assets/scss/vendor/gogo/themes/gogo.light.blue.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/vhms/themes/light.vhms.css';

// Layout
import LayoutPublic from "./containers/layout/public/Layout";
import LayoutPrivate from "./containers/layout/private/Layout";

import PrivateRoute from "./components/common/PrivateRoute";
import PublicRoute from "./components/common/PublicRoute";
import Loader from "./components/loader/Loader";

/***************************************************************
 * VIEWS
 ***************************************************************/
// Public
import Signup from "./views/public/Signup";
import Signin from "./views/public/Signin";
import Logout from "./views/public/Logout";

// Session
import ConfirmAccount from './views/session/ConfirmAccount';
import Plans from './views/session/Plans';
import Profile from "./views/session/Profile";

// Private
import Dashboard from "./views/private/Dashboard";
import ExamsHome from "./views/private/exams";
import ExamNew from "./views/private/exams/new";

class App extends Component {

    componentDidMount() {
        this.props.changeLocale(detectBrowserLanguage());
    }

    render() {
        const currentAppLocale = AppLocale[this.props.locale];

        if (this.props.auth.loading) {
            return (
                <Loader />
            );
        } else if (this.props.auth.isAuthenticated && this.props.auth.user.active === false) {
            // Session - Check Confirmation
            return (
                <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                    <LayoutPublic>
                        <Switch>
                            <PrivateRoute path="/logout" exact component={Logout} />
                            <PrivateRoute path="/confirm" exact component={ConfirmAccount} />
                            <PrivateRoute path="/confirm/:user/:challenge" component={ConfirmAccount} />
                            <Redirect to="/confirm" />
                        </Switch>
                    </LayoutPublic>
                </IntlProvider>
            );
        } else if (this.props.auth.isAuthenticated && this.props.auth.user.active === true && this.props.auth.user.status === "NO_PLAN") {
            // Session - Check Plan
            return (
                <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                    <LayoutPublic>
                        <Switch>
                            <PrivateRoute path="/logout" exact component={Logout} />
                            <PrivateRoute path="/plans" exact component={Plans} />
                            <Redirect to="/plans" />
                        </Switch>
                    </LayoutPublic>
                </IntlProvider>
            );
        } else if (this.props.auth.isAuthenticated && this.props.auth.user.active === true && this.props.auth.user.status === "NO_PROFILE") {
            // Session - Profile
            return (
                <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                    <LayoutPublic>
                        <Switch>
                            <PrivateRoute path="/logout" exact component={Logout} />
                            <PrivateRoute path="/profile" exact component={Profile} />
                            <Redirect to="/profile" />
                        </Switch >
                    </LayoutPublic >
                </IntlProvider>
            );
        } else {
            let routes = (
                <Switch>
                    <PrivateRoute path="/" exact component={Dashboard} />
                    <Route path="/confirm/:user/:challenge" component={ConfirmAccount} />
                    <PublicRoute path="/signup" exact component={Signup} />
                    <PublicRoute path="/signin" exact component={Signin} />
                    <PublicRoute path="/signin/:client/:token" component={Signin} />
                    <PrivateRoute path="/dashboard" exact component={Dashboard} />
                    <PrivateRoute path="/logout" exact component={Logout} />
                    <PrivateRoute path="/plans" exact component={Plans} />
                    <PrivateRoute path="/profile" exact component={Profile} />
                    <PrivateRoute path="/exams" exact component={ExamsHome} />
                    <PrivateRoute path="/exams/new" exact component={ExamNew} />
                    <PrivateRoute path="/diagnostics" exact component={Profile} />
                    <PrivateRoute path="/diagnostics/new" exact component={Dashboard} />
                    <Redirect to="/" />
                </Switch>
            );

            if (this.props.auth.isAuthenticated && this.props.auth.user.active === true && this.props.auth.user.status === "OK") {
                return (
                    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                        <LayoutPrivate>{routes}</LayoutPrivate>
                    </IntlProvider>
                );
            } else {
                return (
                    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                        <LayoutPublic>{routes}</LayoutPublic>
                    </IntlProvider>
                );
            }
        }
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        locale: state.settings.locale
    };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onTryAutoSignup: () => dispatch(actions.authCheckState())
//     };
// };

export default withRouter(connect(mapStateToProps, { changeLocale })(App));
