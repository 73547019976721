import React, { Component, Fragment } from 'react';

import Navbar from './Navbar';
import Footer from './Footer';

class Layout extends Component {
    render() {
        return (
            <Fragment>
                <Navbar history={this.props.history} />
                {this.props.children}
                <Footer />
            </Fragment >
        )
    }
}

export default Layout;


