module.exports = {
  /* 01.General */
  "general.copyright": "VHMS © 2018 All Rights Reserved.",
  "general.name.short": "VHMS",
  "general.name.long": "Virtual Health Medical Solutions",

  /* 02.User Login, Logout, Register */
  "user.login-title": "Login",
  "user.register": "Register",
  "user.forgot-password": "Forgot Password",
  "user.email": "E-mail",
  "user.password": "Password",
  "user.forgot-password-question": "Forget password?",
  "user.fullname": "Full Name",
  "user.login-button": "LOGIN",
  "user.register-button": "REGISTER",
  "user.reset-password-button": "RESET",

  /* 03.Menu */
  "menu.app": "Home",
  "menu.gogo": "Gogo",
  "menu.start": "Start",
  "menu.second-menu": "Second Menu",
  "menu.second": "Second",
  "menu.ui": "UI",
  "menu.charts": "Charts",
  "menu.chat": "Chat",
  "menu.survey": "Survey",
  "menu.todo": "Todo",

  /* 02.Sidebar */
  "sidebar.home": "Home",
  "sidebar.exams": "Exams",
  "sidebar.exams.home": "My Exams",
  "sidebar.diagnostics": "Diagnostics",
  "sidebar.diagnostics.home": "My Diagnostics",

  /* 03.Navbar */
  "navbar.why": "Why VHMS?",
  "navbar.services": "Services",
  "navbar.pricing": "Pricing",
  "navbar.search": "Search",
  "navbar.logout": "Sign Out",
  "navbar.settings": "Settings",
  "navbar.change.account": "Change your Account",

  /* 04.Error Page */
  "layouts.error-title": "Ooops... looks like an error occurred!",
  "layouts.error-code": "Error code",
  "layouts.go-back-home": "GO BACK HOME",

  /* 04.Footer */
  "footer.blog": "Blog",
  "footer.privacy": "Privacy",
  "footer.cookies": "Cookies",
  "footer.about": "About Us",

  /* VIEW: Home */
  "home.title": "Home",
  "home.my.records": "My Records",
  "home.my.feed": "My Feed",
  "home.my.health": "My Health",
  "home.my.calendar": "My Calendar",
  "home.my.lifecard": "My LifeCard",
  "home.my.lifecard.click": "Click to See your Card",

  "home.my.records.areas": "Human Body Systems",
  "home.my.records.areas.general": "General",
  "home.my.records.areas.circulatory": "Circulatory",
  "home.my.records.areas.digestive": "Digestive",
  "home.my.records.areas.endocrine": "Endocrine",
  "home.my.records.areas.exocrine": "Exocrine",
  "home.my.records.areas.excretory": "Excretory",
  "home.my.records.areas.lymphatic": "Lymphatic",
  "home.my.records.areas.muscular": "Muscular",
  "home.my.records.areas.nervous": "Nervous",
  "home.my.records.areas.reprodutive": "Reprodutive",
  "home.my.records.areas.respiratory": "Respiratory",
  "home.my.records.areas.skeletal": "Skeletal",

  /* VIEW: signin */
  "view.signin.title": "Welcome!",
  "view.signin.text.line.1": "Please use your credentials to login.",
  "view.signin.text.line.2.prefix": "If you are not a member, please",
  "view.signin.text.line.2.link": "register",
  "view.signin.text.line.2.suffix": ".",
  "view.signin.text.social": "Sign in with",
  "view.signin.text.local": "Or sign in with credentials",
  "view.signin.email": "Email",
  "view.signin.password": "Password",
  "view.signin.button": "Sign In",
  "view.signin.loading": "Loading...",
  "view.signin.ph.email": "you@example.com",
  "view.signin.ph.password": "Your Password",

  /* VIEW: signup */
  "view.signup.text.title": "Welcome!",
  "view.signup.text.1": "Please fill the form to create your credentials.",
  "view.signup.text.2.prefix": "If you are already a member, please ",
  "view.signup.text.2.link": "click here",
  "view.signup.text.2.suffix": ".",
  "view.signup.lb.name": "Full Name",
  "view.signup.lb.email": "Email",
  "view.signup.lb.password": "Password",
  "view.signup.ph.name": "Tell us your name",
  "view.signup.ph.email": "you@example.com",
  "view.signup.ph.password": "Create a password",
  "view.signup.button": "Sing up for VHMS",
  "view.signup.button.loading": "Loading...",
  "view.signup.terms.1.prefix": "By clicking \"Sign up for VHMS\", you agree to our ",
  "view.signup.terms.1.link": "terms of service and privacy statement",
  "view.signup.terms.1.suffix": ".",
  "view.signup.terms.2": "We’ll occasionally send you account related emails.",

  /* VIEW: Confirm Account */
  "view.confirm.title": "Verify your Account",
  "view.confirm.logged.text": "We have sent you an email to {email} with a link to confirm your account. Please, check your email to confirm your account. If you did not receive this email, click in the link below:",
  "view.confirm.logged.button": "Resend Confirmation Link",
  "view.confirm.not.logged.text": "We have sent you an email with a link to confirm your account. Please, check your email to confirm your account. If you did not receive this email, please sign in in the link below: ",
  "view.confirm.not.logged.button": "Sign In",

  /* VIEW: Profile */
  "view.profile.title": "My Profile",
  "view.profile.user.title": "User Information",
  "view.profile.contact.title": "Contact Information",
  "view.profile.work.title": "Work Information",

  "view.profile.button.save": "Save",
  "view.profile.button.jump": "Skip Step",
  "view.profile.button.loading": "Loading...",

  "view.profile.name": "Name",
  "view.profile.name.ph": "Tell us your name...",
  "view.profile.birthday": "Date of Birth",
  "view.profile.birthday.ph": "Select your Date of Birth...",

  "view.profile.gender": "Gender",
  "view.profile.gender.ph": "Select your gender...",
  "view.profile.gender.male": "Male",
  "view.profile.gender.female": "Female",

  "view.profile.civil": "Marital Status",
  "view.profile.civil.ph": "Select your Status...",
  "view.profile.civil.single": "Single",
  "view.profile.civil.married": "Married",
  "view.profile.civil.widowed": "Widowed",
  "view.profile.civil.divorced": "Divorced",
  "view.profile.civil.separated": "Separeted",

  "view.profile.address": "Address",
  "view.profile.address.ph": "Type your Address...",
  "view.profile.city": "City",
  "view.profile.city.ph": "Type your City...",
  "view.profile.country": "Country",
  "view.profile.country.ph": "Type your Country...",
  "view.profile.postal": "Postal Code",
  "view.profile.postal.ph": "Type your Postal Code...",

  "view.profile.occupation": "Occupation",
  "view.profile.occupation.ph": "Type your Occupation...",

  "view.profile.occupation.length": "Occupation Length",
  "view.profile.occupation.length.ph": "Select your Occupation Length...",
  "view.profile.workp": "Work Pattern",
  "view.profile.workp.ph": "Select your Work Pattern...",

};
