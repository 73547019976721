import React, { Component } from "react";
import ReactDOM from "react-dom";
import { injectIntl } from 'react-intl';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, NavItem } from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { setContainerClassnames, addContainerClassname, changeDefaultClassnames } from "../../../store/actions/index";

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.addEvents = this.addEvents.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleProps = this.handleProps.bind(this);
        this.removeEvents = this.removeEvents.bind(this);
        this.getContainer = this.getContainer.bind(this);
        this.getMenuClassesForResize = this.getMenuClassesForResize.bind(this);
        this.setSelectedLiActive = this.setSelectedLiActive.bind(this);
        this.getClass = this.getClass.bind(this);

        this.state = {
            selectedParentMenu: "",
            viewingParentMenu: "",
        };
    }

    handleWindowResize(event) {
        if (event && !event.isTrusted) { return; }
        const { containerClassnames } = this.props;
        let nextClasses = this.getMenuClassesForResize(containerClassnames);
        this.props.setContainerClassnames(0, nextClasses.join(" "));
    }

    handleDocumentClick(e) {
        const container = this.getContainer();
        let isMenuClick = false;
        if (
            e.target &&
            e.target.classList &&
            (e.target.classList.contains("menu-button") || e.target.classList.contains("menu-button-mobile"))
        ) {
            isMenuClick = true;
        } else if (
            e.target.parentElement &&
            e.target.parentElement.classList &&
            (e.target.parentElement.classList.contains("menu-button") || e.target.parentElement.classList.contains("menu-button-mobile"))
        ) {
            isMenuClick = true;
        } else if (
            e.target.parentElement &&
            e.target.parentElement.parentElement &&
            e.target.parentElement.parentElement.classList &&
            (e.target.parentElement.parentElement.classList.contains("menu-button") || e.target.parentElement.parentElement.classList.contains("menu-button-mobile"))
        ) {
            isMenuClick = true;
        }

        if ((container.contains(e.target) && container !== e.target) || isMenuClick) { return; }

        this.toggle(e);
        this.setState({
            viewingParentMenu: ""
        })
    }

    getMenuClassesForResize(classes) {
        const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.props;
        let nextClasses = classes.split(" ").filter(x => x !== "");
        const windowWidth = window.innerWidth;
        if (windowWidth < menuHiddenBreakpoint) {
            nextClasses.push("menu-mobile");
        } else if (windowWidth < subHiddenBreakpoint) {
            nextClasses = nextClasses.filter(x => x !== "menu-mobile");
            if (nextClasses.includes("menu-default") && !nextClasses.includes("menu-sub-hidden")) {
                nextClasses.push("menu-sub-hidden");
            }
        } else {
            nextClasses = nextClasses.filter(x => x !== "menu-mobile");
            if (nextClasses.includes("menu-default") && nextClasses.includes("menu-sub-hidden")) {
                nextClasses = nextClasses.filter(x => x !== "menu-sub-hidden");
            }
        }
        return nextClasses;
    }

    getContainer() {
        return ReactDOM.findDOMNode(this);
    }

    toggle() {
        const { containerClassnames, menuClickCount } = this.props;
        const currentClasses = containerClassnames ? containerClassnames.split(" ").filter(x => x !== "") : "";

        if (currentClasses.includes("menu-sub-hidden") && menuClickCount === 3) {
            this.props.setContainerClassnames(2, containerClassnames);
        } else if (currentClasses.includes("menu-hidden") || currentClasses.includes("menu-mobile")) {
            this.props.setContainerClassnames(0, containerClassnames);
        }
    }

    handleProps() {
        this.addEvents();
    }

    addEvents() {
        ["click", "touchstart"].forEach(event =>
            document.addEventListener(event, this.handleDocumentClick, true)
        );
    }
    removeEvents() {
        ["click", "touchstart"].forEach(event =>
            document.removeEventListener(event, this.handleDocumentClick, true)
        );
    }

    setSelectedLiActive() {
        const oldli = document.querySelector(".sub-menu  li.active");
        if (oldli != null) {
            oldli.classList.remove("active");
        }

        /* set selected parent menu */
        const selectedlink = document.querySelector(".sub-menu  a.active");
        if (selectedlink != null) {
            selectedlink.parentElement.classList.add("active");
            this.setState({
                selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute("data-parent")
            });
        } else {
            var selectedParentNoSubItem = document.querySelector(".main-menu  li a.active");
            if (selectedParentNoSubItem != null) {
                this.setState({
                    selectedParentMenu: selectedParentNoSubItem.getAttribute("data-flag")
                });
            } else if (this.state.selectedParentMenu === "") {
                this.setState({
                    selectedParentMenu: "home"
                });
            }

        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setSelectedLiActive();
            this.toggle();
            window.scrollTo(0, 0);
        }

        this.handleProps();
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowResize);
        this.handleWindowResize();
        this.handleProps();
        this.setSelectedLiActive();
    }

    componentWillUnmount() {
        this.removeEvents();
        window.removeEventListener("resize", this.handleWindowResize);
    }

    openSubMenu(e, selectedParent) {
        e.preventDefault();
        const { containerClassnames, menuClickCount } = this.props;
        const currentClasses = containerClassnames ? containerClassnames.split(" ").filter(x => x !== "") : "";

        if (!currentClasses.includes("menu-mobile")) {
            if (currentClasses.includes("menu-sub-hidden") && (menuClickCount === 2 || menuClickCount === 0)) {
                this.props.setContainerClassnames(3, containerClassnames);
            } else if (currentClasses.includes("menu-hidden") && (menuClickCount === 1 || menuClickCount === 3)) {
                this.props.setContainerClassnames(2, containerClassnames);
            } else if (currentClasses.includes("menu-default") && !currentClasses.includes("menu-sub-hidden") && (menuClickCount === 1 || menuClickCount === 3)) {
                this.props.setContainerClassnames(0, containerClassnames);
            }
        } else {
            this.props.addContainerClassname("sub-show-temporary", containerClassnames);
        }
        this.setState({
            viewingParentMenu: selectedParent
        });
    }

    closeSubMenu(e, menuName) {
        this.toggle();
        this.setState({
            selectedParentMenu: menuName,
            viewingParentMenu: ""
        });
    }

    getClass(value, classname) {
        // console.log(this.state);
        if ((this.state.selectedParentMenu === value && this.state.viewingParentMenu === "") || this.state.viewingParentMenu === value) {
            return classname;
        }
        return "";
    };

    render() {
        const { intl } = this.props;

        return (
            <div className="sidebar">
                <div className="main-menu">
                    <div className="scroll">
                        <PerfectScrollbar option={{ suppressScrollX: true, wheelPropagation: false }}>
                            <Nav vertical className="list-unstyled">
                                <NavItem className={this.getClass("home", "active")} >
                                    <NavLink to="/" exact data-flag="home" onClick={e => this.closeSubMenu(e, "home")}>
                                        <i className="iconsmind-Home" />{" "}
                                        {intl.formatMessage({ id: "sidebar.home" })}
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.getClass("exams", "active")} >
                                    <NavLink to="/exams" exact data-flag="exams" onClick={e => this.closeSubMenu(e, "exams")}>
                                        <i className="iconsmind-Chemical-3" />{" "}
                                        {intl.formatMessage({ id: "sidebar.exams" })}
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.getClass("diagnostics", "active")} >
                                    <NavLink to="/diagnostics" exact data-flag="diagnostics" onClick={e => this.openSubMenu(e, "diagnostics")}>
                                        <i className="iconsmind-Stethoscope" />{" "}
                                        {intl.formatMessage({ id: "sidebar.diagnostics" })}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </PerfectScrollbar>
                    </div>
                </div>

                <div className="sub-menu">
                    <div className="scroll">
                        <PerfectScrollbar option={{ suppressScrollX: false, wheelPropagation: true }} >
                            <Nav data-parent="exams" className={this.getClass("exams", "d-block")}>
                                <NavItem>
                                    <NavLink to="/exams/new">
                                        <i className="simple-icon-paper-plane" />{" "}
                                        {intl.formatMessage({ id: "sidebar.exams.home" })}
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <Nav data-parent="diagnostics" className={this.getClass("diagnostics", "d-block")} >
                                <NavItem>
                                    <NavLink to="/diagnostics/new">
                                        <i className="simple-icon-paper-plane" />{" "}
                                        {intl.formatMessage({ id: "sidebar.diagnostics.home" })}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div >
        );
    }
}


const mapStateToProps = state => {
    return {
        containerClassnames: state.menu.containerClassnames,
        menuClickCount: state.menu.menuClickCount,
        subHiddenBreakpoint: state.menu.subHiddenBreakpoint,
        menuHiddenBreakpoint: state.menu.menuHiddenBreakpoint,
        locale: state.settings.locale,
    };
};

const mapDispatchToProps = { setContainerClassnames, addContainerClassname, changeDefaultClassnames };

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar)));
