import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import PerfectScrollbar from "react-perfect-scrollbar";
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu, Input, Navbar } from "reactstrap";
import human from 'humanparser';

import { setContainerClassnames, clickOnMobileMenu } from "../../../store/actions/index";
import notifications from "../../../utils/topnav.data.json";

class TopNav extends Component {
    constructor(props) {
        super(props);
        this.menuButtonClick = this.menuButtonClick.bind(this);
        this.mobileMenuButtonClick = this.mobileMenuButtonClick.bind(this);
    };

    menuButtonClick(e, menuClickCount, containerClassnames) {
        e.preventDefault();

        setTimeout(() => {
            var event = document.createEvent("HTMLEvents");
            event.initEvent("resize", false, false);
            window.dispatchEvent(event);
        }, 350);
        this.props.setContainerClassnames(++menuClickCount, containerClassnames);
    };

    mobileMenuButtonClick(e, containerClassnames) {
        e.preventDefault();
        this.props.clickOnMobileMenu(containerClassnames);
    };

    render() {
        const { containerClassnames, menuClickCount } = this.props;
        const { intl } = this.props;
        const user = this.props.auth.user;
        const username = human.parseName(this.props.auth.user.name);

        return (
            <Navbar className="fixed-top">
                <NavLink to="#" className="menu-button d-none d-md-block" onClick={e => this.menuButtonClick(e, menuClickCount, containerClassnames)}>
                    <svg className="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 18">
                        <rect x="0" y="0" width="7" height="2" />
                        <rect x="0" y="8" width="7" height="2" />
                        <rect x="0" y="16" width="7" height="2" />
                    </svg>
                    <svg className="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                        <rect x="1.56" y="0" width="16" height="2" />
                        <rect x="1.56" y="8" width="16" height="2" />
                        <rect x="1.56" y="16" width="16" height="2" />
                    </svg>
                </NavLink>
                <NavLink to="#" className="menu-button-mobile d-xs-block d-sm-block d-md-none" onClick={e => this.mobileMenuButtonClick(e, containerClassnames)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 18">
                        <rect x="0" y="0" width="25" height="2" />
                        <rect x="0" y="8" width="25" height="2" />
                        <rect x="0" y="16" width="25" height="2" />
                    </svg>
                </NavLink>

                <div className="search">
                    <Input
                        name="searchKeyword"
                        id="searchKeyword"
                        placeholder={intl.formatMessage({ id: "navbar.search" })}
                    />
                    {/* <span className="search-icon">
                        <i className="simple-icon-magnifier" />
                    </span> */}
                </div>

                <NavLink className="navbar-logo" to="/">
                    <span className="logo d-none d-xs-block" />
                    <span className="logo-mobile d-block d-xs-none" />
                </NavLink>

                <div className="ml-auto">
                    <div className="header-icons d-inline-block align-middle">
                        <div className="position-relative d-none d-sm-inline-block">
                            <UncontrolledDropdown className="dropdown-menu-right">
                                <DropdownToggle className="header-icon" color="empty">
                                    <i className="simple-icon-grid" />
                                </DropdownToggle>
                                <DropdownMenu className="position-absolute mt-2" right id="iconMenuDropdown" >
                                    <NavLink to="/dashboard" className="icon-menu-item">
                                        <i className="iconsmind-Air-Balloon d-block" />{" "}
                                        {intl.formatMessage({ id: "menu.gogo" })}
                                    </NavLink>
                                    <NavLink to="/profile" className="icon-menu-item">
                                        <i className="iconsmind-Pantone d-block" />{" "}
                                        {intl.formatMessage({ id: "menu.ui" })}
                                    </NavLink>
                                    <NavLink to="#" className="icon-menu-item">
                                        <i className="iconsmind-Bar-Chart d-block" />{" "}
                                        {intl.formatMessage({ id: "menu.charts" })}
                                    </NavLink>
                                    <NavLink to="#" className="icon-menu-item">
                                        <i className="iconsmind-Speach-BubbleDialog d-block" />{" "}
                                        {intl.formatMessage({ id: "menu.chat" })}
                                    </NavLink>
                                    <NavLink to="#" className="icon-menu-item">
                                        <i className="iconsmind-Formula d-block" />{" "}
                                        {intl.formatMessage({ id: "menu.survey" })}
                                    </NavLink>
                                    <NavLink to="#" className="icon-menu-item">
                                        <i className="iconsmind-Check d-block" />{" "}
                                        {intl.formatMessage({ id: "menu.todo" })}
                                    </NavLink>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>

                        <div className="position-relative d-inline-block">
                            <UncontrolledDropdown className="dropdown-menu-right">
                                <DropdownToggle className="header-icon notificationButton" color="empty">
                                    <i className="simple-icon-bell" />
                                </DropdownToggle>
                                <DropdownMenu id="notificationDropdown" className="position-absolute mt-2 scroll" right>
                                    <PerfectScrollbar option={{ suppressScrollX: true, wheelPropagation: false }}>
                                        {notifications.data.map((n, index) => {
                                            return (
                                                <div key={index} className="d-flex flex-row mb-3 pb-3 border-bottom">
                                                    <a href="/app">
                                                        <img src={n.image} alt="" className="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                                                    </a>
                                                    <div className="pl-3 pr-2">
                                                        <a href="/app">
                                                            <p className="font-weight-medium mb-1">
                                                                {n.message}
                                                            </p>
                                                            <p className="text-muted mb-0 text-small">
                                                                {n.date}
                                                            </p>
                                                        </a>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </PerfectScrollbar>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>

                    <div className="user d-inline-block pr-2">
                        <UncontrolledDropdown className="dropdown-menu-right">
                            <DropdownToggle className="p-0" color="empty">
                                <span className="name mr-1">{username.firstName}</span>
                                <span>
                                    <img alt="" src={user.avatar} />
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className="mt-2" right>
                                <DropdownItem header>{intl.formatMessage({ id: "navbar.change.account" })}</DropdownItem>
                                <DropdownItem className="dropdown-profile">
                                    <span className="mr-2"><img alt="" src="/assets/img/profile-pic-l-3.jpg" /></span>
                                    <span className="name">John Doe</span>
                                </DropdownItem>
                                <DropdownItem className="dropdown-profile">
                                    <span className="mr-2"><img alt="" src="/assets/img/profile-pic-l-4.jpg" /></span>
                                    <span className="name">Jane Doe</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>{intl.formatMessage({ id: "navbar.settings" })}</DropdownItem>
                                <DropdownItem divider />
                                <NavLink to="/logout">
                                    <DropdownItem>
                                        {intl.formatMessage({ id: "navbar.logout" })}
                                    </DropdownItem>
                                </NavLink>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            </Navbar>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        containerClassnames: state.menu.containerClassnames,
        menuClickCount: state.menu.menuClickCount,
    };
};

const mapDispatchToProps = { setContainerClassnames, clickOnMobileMenu };

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TopNav));
