import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import axios from "axios";

import { Upload, Icon } from 'antd';
import { Debug } from "../../../components/formik/formikDebug";
const Dragger = Upload.Dragger;

class Dashboard extends Component {
  state = {
    fileList: [],
    uploading: false,
  }

  render() {
    return (
      <Container fluid>
        <Row className="mb-4">
          <Col>
            <Card className="">
              <CardBody>
                <div className="container">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      attachments: [],
                    }}
                    onSubmit={async (values) => {
                      const data = new FormData();

                      data.set("name", values.name);
                      data.set("email", values.email);

                      // const file = new Blob([files[0]]); // kind of works and choses stream as content type of file (not request)
                      // const file = new Blob([files[0]], { type: 'image/png' });// WORKS much better (if you know what MIME type you want.

                      // const formData = new FormData();
                      // formData.append('test', file, file.filename); 

                      for (let i = 0; i <= this.state.fileList.length - 1; i++) {
                        data.append("files", this.state.fileList[i], this.state.fileList[i].filename);
                        // data.append(`files[${i}]`, this.state.fileList[i], this.state.fileList[i].filename);
                        //data.append(`file`, this.state.fileList[i], this.state.fileList[i].filename);
                      }

                      axios.post('http://localhost:8080/exams/new',
                        data,
                        { headers: { 'Content-Type': 'multipart/form-data' } })
                        .then(function (response) {
                          console.log("OK");
                        })
                        .catch(function (error) {
                          //console.log(error);
                        });
                    }}
                    // validationSchema={yup.object().shape({
                    //   name: yup.string().required(),
                    //   email: yup.string().email().required(),
                    // })}
                    render={({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label for="name">Name</label>
                          <input id="name" name="name" type="text" className="form-control"
                            value={values.name} onChange={handleChange} />
                          {errors.name && touched.name && (
                            <p>{errors.name}</p>
                          )}
                        </div>

                        <div className="form-group">
                          <label for="email">E-mail</label>
                          <input id="email" name="email" type="email" className="form-control"
                            value={values.email} onChange={handleChange} />
                          {errors.email && touched.email && (
                            <p>{errors.email}</p>
                          )}
                        </div>

                        <div className="form-group">
                          <label>Multiple files</label>


                          <Dragger
                            multiple={true}
                            onRemove={(file) => {
                              this.setState((state) => {
                                const index = state.fileList.indexOf(file);
                                const newFileList = state.fileList.slice();
                                newFileList.splice(index, 1);
                                return {
                                  fileList: newFileList,
                                };
                              });
                              setFieldValue("attachments", this.state.fileList);
                            }}
                            beforeUpload={(file) => {
                              this.setState(state => ({
                                fileList: [...state.fileList, file],
                              }));
                              setFieldValue("attachments", this.state.fileList);
                              return false;
                            }}

                          >
                            <p className="ant-upload-drag-icon">
                              <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p>
                          </Dragger>
                        </div>

                        <button type="submit" className="btn btn-primary">submit</button>

                        <Debug />
                      </form>
                    )} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container >
    );
  }
}

export default injectIntl(Dashboard);
