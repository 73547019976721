import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { injectIntl } from 'react-intl';

import * as actions from "../../store/actions/index";

import { Button, Row, Col, Container, Form, FormGroup, Card, Label } from "reactstrap";
import TextFieldGroup from "../../components/common/TextFieldGroup";
// import { Colxx } from "../../components/customBootstrap";

class Auth extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            rememberme: false
        };

        this.handleChange = this.handleChange.bind(this);
    };

    componentDidMount() {
        document.body.classList.add("background");

        // Clean Errors
        this.props.onAuthReset();

        // Read QueryParams
        const values = this.props.match.params;

        // Check Token
        if (values.token) {
            if (values.client) {
                if (values.client === "google") {
                    this.props.onLoginGoogle(values.token);
                } else if (values.client === "facebook") {
                    this.props.onLoginFacebook(values.token);
                }
            }
        }
    };

    componentWillUnmount() {
        document.body.classList.remove("background");
    }

    handleChange = async event => {
        const { target } = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [name]: value
        });
    };

    submitHandler = event => {
        event.preventDefault();
        this.props.onLogin(this.state.email, this.state.password);
    };

    facebookResponse = response => {
        this.props.onLoginFacebook(response.accessToken);
    };

    googleResponse = (response) => {
        this.props.onLoginGoogle(response.accessToken);
    };

    render() {
        const { intl } = this.props;

        let error_email = null;
        let error_password = null;

        if (this.props.error) {
            error_email = this.props.error.email;
            error_password = this.props.error.password;
        }

        return (
            <Fragment>
                <div className="fixed-background bg-primary-light" />
                <main>
                    <Container>
                        <Row className="h-100">
                            <Col xs="12" md="10" className="mx-auto my-auto">
                                <Card className="auth-card">
                                    <div className="position-relative image-side bg-info-light">
                                        <p className="text-white h2 mb-3">{intl.formatMessage({ id: 'view.signin.title' })}</p>
                                        <p className="white">
                                            {intl.formatMessage({ id: 'view.signin.text.line.1' })}<br />
                                            {intl.formatMessage({ id: 'view.signin.text.line.2.prefix' })}{" "}
                                            <NavLink to={'/signup'} className="white"><b>{intl.formatMessage({ id: 'view.signin.text.line.2.link' })}</b></NavLink>
                                            {intl.formatMessage({ id: 'view.signin.text.line.2.suffix' })}
                                        </p>
                                    </div>
                                    <div className="form-side">
                                        <div>
                                            <div className="text-muted text-center mt-2 mb-3">
                                                <small>{intl.formatMessage({ id: 'view.signin.text.social' })}</small>
                                            </div>
                                            <div className="btn-wrapper text-center">
                                                <a href={process.env.REACT_APP_API_URI + "/auth/google"} className="btn btn-lg btn-neutral btn-icon" >
                                                    <img slot="icon" src="/images/icons/google.svg" alt="" height="24px" />
                                                    <span className="btn-inner--text">Google</span>
                                                </a>
                                                <a href={process.env.REACT_APP_API_URI + "/auth/facebook"} className="btn btn-lg btn-neutral btn-icon" >
                                                    <img slot="icon" src="/images/icons/facebook.svg" alt="" height="24px" />
                                                    <span className="btn-inner--text">Facebook</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="pt-4 py-lg-4">
                                            <div className="text-center text-muted mb-4">
                                                <small>
                                                    {intl.formatMessage({ id: 'view.signin.text.local' })}
                                                </small>
                                            </div>
                                            <Form className="form" role="form" onSubmit={this.submitHandler} >
                                                <FormGroup className="mb-3">
                                                    <Label className="form-control-label" htmlFor="input-email">{intl.formatMessage({ id: 'view.signin.email' })}</Label>
                                                    <TextFieldGroup
                                                        id="input-email"
                                                        type="email"
                                                        placeholder={intl.formatMessage({ id: 'view.signin.ph.email' })}
                                                        name="email"
                                                        className="form-control form-control-alternative"
                                                        value={this.state.email}
                                                        onChange={this.handleChange}
                                                        error={error_email}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="form-control-label" htmlFor="input-password">{intl.formatMessage({ id: 'view.signin.password' })}</Label>
                                                    <TextFieldGroup
                                                        id="input-password"
                                                        type="password"
                                                        placeholder={intl.formatMessage({ id: 'view.signin.ph.password' })}
                                                        name="password"
                                                        className="form-control form-control-alternative"
                                                        value={this.state.password}
                                                        onChange={this.handleChange}
                                                        error={error_password}
                                                    />
                                                </FormGroup>

                                                <Button color="info" className="mt-5 py-3 btn-lg btn-block">{this.props.loading ? intl.formatMessage({ id: 'view.signin.loading' }) : intl.formatMessage({ id: 'view.signin.button' })}</Button>
                                            </Form>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </main>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.validating,
        error: state.auth.error,
        isAuthenticated: state.auth.isAuthenticated,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (email, password) => dispatch(actions.signIn(email, password)),
        onLoginGoogle: (accessToken) => dispatch(actions.signInGoogle(accessToken)),
        onLoginFacebook: (accessToken) => dispatch(actions.signInFacebook(accessToken)),
        onAuthReset: () => dispatch(actions.authReset())
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Auth));
