import React, { Component } from 'react';
import { connect } from "react-redux";

import Sidebar from './Sidebar';
import Navbar from './Navbar';

class Layout extends Component {
    render() {
        return (
            <div id="app-container" className={this.props.containerClassnames}>
                <Navbar history={this.props.history}></Navbar>
                <Sidebar />

                <div className="container-fluid">
                    <main>
                        {this.props.children}
                    </main>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        containerClassnames: state.menu.containerClassnames,
    };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);

