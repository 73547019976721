import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardFooter, Badge, CardHeader } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink } from "react-router-dom";
import BigCalendar from "react-big-calendar";
import moment from "moment";
import { injectIntl } from "react-intl";

import { CalendarToolbar } from "../../components/calendar/calendarToolbar";

import logsData from "../../utils/data/logs.json";
import feedData from "../../utils/data/feed.json";
import eventsData from "../../utils/data/events.json";
const logs = logsData.data;
const feed = feedData.data;
const events = eventsData.data;

// Human Systems
const systems = [
    { name: "general", icon: "Stethoscope", color: "info" },
    { name: "circulatory", icon: "Cardiovascular", color: "info" },
    { name: "digestive", icon: "X-ray", color: "info" },
    { name: "endocrine", icon: "Chemical", color: "info" },
    { name: "exocrine", icon: "Hair", color: "info" },
    { name: "excretory", icon: "X-ray", color: "info" },
    { name: "lymphatic", icon: "Virus", color: "info" },
    { name: "muscular", icon: "Bodybuilding", color: "info" },
    { name: "nervous", icon: "Brain", color: "info" },
    { name: "reprodutive", icon: "Bisexual", color: "info" },
    { name: "respiratory", icon: "Brain-2", color: "info" },
    { name: "skeletal", icon: "Bones", color: "info" },
];

const localizer = BigCalendar.momentLocalizer(moment);

class Dashboard extends Component {

    render() {
        const { intl } = this.props;

        return (
            <Container fluid>
                {/* <Row>
                    <Col xs="12">
                        <BreadcrumbContainer heading={intl.formatMessage({ id: "home.title" })} match={this.props.match} />
                        <div className="separator mb-4" />
                    </Col>
                </Row>  */}

                <Row className="mb-4">
                    <Col>
                        <Card className="">
                            <CardHeader className="text-white card-header-dashboard bg-dark">
                                {intl.formatMessage({ id: "home.my.records" })}
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col lg="4">
                                        <div className="d-flex align-items-center align-self-center">
                                            <img src="/images/resources/human.png" className="dashboard-records-img mx-auto my-auto" alt="" />
                                        </div>
                                    </Col>
                                    <Col lg="8">
                                        <CardBody className="text-center">
                                            <div className="my-3 mb-4 mt-lg-0">
                                                <h4 className="font-weight-bold">{intl.formatMessage({ id: "home.my.records.areas" })}</h4>
                                            </div>
                                            <div className="d-flex flex-row flex-wrap mb-3 justify-content-center">
                                                {systems.map((system) => {
                                                    return (
                                                        <NavLink to="/" class="d-flex flex-column my-3 mx-3 dash-records-item">
                                                            <div className="align-self-center align-items-center dash-records-item-thumbnail">
                                                                <i className={`iconsmind-` + system.icon}></i>
                                                            </div>
                                                            <h6 class="text-center">{intl.formatMessage({ id: "home.my.records.areas." + system.name })}</h6>
                                                        </NavLink>
                                                    );
                                                })}
                                            </div>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="8" md="12" className="mb-4">
                        <Card>
                            <CardHeader className="bg-info-light text-white card-header-dashboard">
                                {intl.formatMessage({ id: "home.my.feed" })}
                                <div className="position-absolute card-top-buttons">
                                    <button className="btn btn-header-light icon-button btn-primary">
                                        <i className="simple-icon-refresh" />
                                    </button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className="scroll dashboard-list-with-thumbs">
                                    <PerfectScrollbar option={{ suppressScrollX: true, wheelPropagation: true }}  >
                                        {feed.map((order, index) => {
                                            return (
                                                <div key={index} className="d-flex flex-row mb-3">
                                                    <NavLink to="/" className="d-block position-relative">
                                                        <div className={`align-self-center dashboard-thumbnail-letters bg-` + order.statusColor}>
                                                            <i className={`iconsmind-` + order.img}></i>
                                                        </div>
                                                        <Badge key={index} className="position-absolute badge-top-left" color="secondary" pill >
                                                            {order.status}
                                                        </Badge>
                                                    </NavLink>

                                                    <div className="px-2 pt-1">
                                                        <NavLink to="/app/layouts/details">
                                                            <p className="list-item-heading mb-0">{order.name}</p>
                                                            <div className="pr-2">
                                                                <p className="text-muted mb-1 small">
                                                                    {order.description}
                                                                </p>
                                                            </div>
                                                            <div className="text-primary text-small font-weight-medium d-none d-sm-block">
                                                                {order.createDate}
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </PerfectScrollbar>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="12" className="mb-4">
                        <Card>
                            <CardHeader className="bg-info text-white card-header-dashboard">
                                {intl.formatMessage({ id: "home.my.health" })}
                            </CardHeader>
                            <CardBody>
                                <div className="dashboard-logs">
                                    <PerfectScrollbar option={{ suppressScrollX: true, wheelPropagation: true }} >
                                        <table className="table table-sm table-borderless">
                                            <tbody>
                                                {logs.map((log, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <span className={`log-indicator align-middle ${log.color}`} />
                                                            </td>
                                                            <td>
                                                                <span className="font-weight-medium">{log.label}</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <span className="text-muted">{log.time}</span>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </PerfectScrollbar>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="8" md="12" className="mb-4">
                        <Card>
                            <CardHeader className="bg-dark text-white card-header-dashboard">
                                {intl.formatMessage({ id: "home.my.calendar" })}
                            </CardHeader>
                            <CardBody>
                                <BigCalendar
                                    style={{ minHeight: "500px" }}
                                    events={events}
                                    views={["month"]}
                                    localizer={localizer}
                                    components={{
                                        toolbar: CalendarToolbar
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="12" className="mb-4">
                        <Card className="dashboard-sq-banner">
                            <CardHeader className="text-white card-header-dashboard">
                                {intl.formatMessage({ id: "home.my.lifecard" })}
                            </CardHeader>
                            <CardBody className="d-flex align-items-center align-self-center">
                                <img src="/images/resources/card.png" className="dashboard-lifecard-img" alt="" />
                            </CardBody>
                            <CardFooter className="border-0">
                                <p className="text-white mb-0 text-center">
                                    {intl.formatMessage({ id: "home.my.lifecard.click" })}
                                </p>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container >
        );
    }
}

export default injectIntl(Dashboard);
