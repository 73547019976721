import React, { Component, Fragment } from "react";
import axios from "axios";
import { getAxiosError } from "../../utils/axiosUtils";
import { Container, Row, Col, Button, Card, CardDeck, CardBody, CardFooter, Badge, CardHeader } from "reactstrap";

import { connect } from "react-redux";
import { authUpdateUser } from "../../store/actions/index";

class Plans extends Component {

    componentDidMount() {
        document.body.classList.add("landing");
    }

    componentWillUnmount() {
        document.body.classList.remove("landing");
    }

    submitFree = event => {
        event.preventDefault();

        const payload = {
            type: "FREE"
        };

        axios
            .post(process.env.REACT_APP_API_URI + "/plans", payload)
            .then(response => {
                console.log(response);
                this.props.authUpdateUser();
            })
            .catch(error => {
                console.log(getAxiosError(error));
            });
    };

    render() {
        return (
            <Fragment>
                <main >
                    <section className="bg-primary-light">
                        <Container>
                            <Row className="justify-content-center py-5">
                                <Col lg="6" md="8" className="text-center">
                                    <h1 className="font-weight-bold">Our Plans</h1>
                                    <p className="lead">
                                        Please, check the plans bellow and choose what you think is the best for you!
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row className="pb-5">
                                <Col>
                                    <CardDeck>
                                        <Card className="border-0 shadow">
                                            <CardHeader className="bg-secondary">
                                                <h3 className="text-white text-center text-uppercase font-weight-bold pt-3 mb-1">Free</h3>
                                            </CardHeader>
                                            <CardBody>
                                                <p className="description text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                <div>
                                                    <Badge color="secondary" className="mr-1">Exams</Badge>
                                                    <Badge color="secondary" className="mr-1">No charge</Badge>
                                                    <Badge color="secondary" className="mr-1">Only you</Badge>
                                                </div>
                                            </CardBody>
                                            <CardFooter className="text-center bg-white">
                                                <h1 className="mb-0">$0</h1>
                                                <small>No credit card required</small>
                                            </CardFooter>
                                            <CardFooter className="text-center bg-white">
                                                <Button color="secondary" size="lg" block outline onClick={this.submitFree}>Try Free</Button>
                                            </CardFooter>
                                        </Card>
                                        <Card className="border-0 shadow">
                                            <CardHeader className="bg-info">
                                                <h3 className="text-white text-center text-uppercase font-weight-bold pt-3 mb-1">Personal</h3>
                                            </CardHeader>
                                            <CardBody>
                                                <p className="description text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                <div>
                                                    <Badge color="info" className="mr-1">business</Badge>
                                                    <Badge color="info" className="mr-1">vision</Badge>
                                                    <Badge color="info" className="mr-1">success</Badge>
                                                </div>
                                            </CardBody>
                                            <CardFooter className="text-center bg-white">
                                                <h1 className="mb-0">$10</h1>
                                                <small>Per Year</small>
                                            </CardFooter>
                                            <CardFooter className="text-center bg-white">
                                                <Button color="info" size="lg" block>Start Now</Button>
                                            </CardFooter>
                                        </Card>
                                        <Card className="border-0 shadow">
                                            <CardHeader className="bg-danger">
                                                <h3 className="text-white text-center text-uppercase font-weight-bold pt-3 mb-1">Family</h3>
                                            </CardHeader>
                                            <CardBody>
                                                <p className="description text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                <div>
                                                    <Badge color="danger" className="mr-1">marketing</Badge>
                                                    <Badge color="danger" className="mr-1">product</Badge>
                                                    <Badge color="danger" className="mr-1">launch</Badge>
                                                </div>
                                            </CardBody>
                                            <CardFooter className="text-center bg-white">
                                                <h1 className="mb-0">$25</h1>
                                                <small>Per Year</small>
                                            </CardFooter>
                                            <CardFooter className="text-center bg-white">
                                                <Button color="danger" size="lg" block>Start Now</Button>
                                            </CardFooter>
                                        </Card>
                                    </CardDeck>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <div>
                        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <polygon className="fill-primary-light" points="0,0 2560,0 0,100"></polygon>
                        </svg>
                    </div>

                    <Container className="my-5 pb-1">
                        <Row className="justify-content-center">
                            <Col lg="6" md="8">
                                <h1 className="text-center">
                                    Still have questions? Get in touch.
                                </h1>
                                <p className="text-center mt-4">
                                    <Button color="primary" size="lg" className="py-3 text-large">Contact Sales</Button>
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </main>
            </Fragment >
        );
    }
}

export default connect(null, { authUpdateUser })(Plans);