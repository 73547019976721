import React from "react";
import { connect, getIn } from "formik";

import { Input, Form } from 'antd';
const FormItem = Form.Item;

class MyInput extends React.Component {
    handleChange = value => {
        console.log(value);
        this.props.onChange(this.props.name, value.target.value);
    };

    handleBlur = () => {
        this.props.onBlur(this.props.name, true);
    };

    render() {
        const error = getIn(this.props.formik.errors, this.props.name);
        const touched = getIn(this.props.formik.touched, this.props.name);

        return (
            <React.Fragment>
                <FormItem
                    className="mb-0"
                    hasFeedback={false}
                    validateStatus={touched && error ? "error" : ""}
                    help={touched && error ? error : ""}
                >
                    <Input {...this.props} onChange={this.handleChange} onBlur={this.handleBlur} />
                </FormItem>
            </React.Fragment>
        );
    }
}

export default connect(MyInput);