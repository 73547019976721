import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';

class FooterLayout extends Component {
    render() {
        return (
            <div className="footer fixed-footer bg-dark d-none d-lg-block">
                <Container>
                    <Row className="align-items-center justify-content-xl-between">
                        <Col xl="6">
                            <div className="copyright text-center text-xl-left text-muted">
                                <a href={process.env.REACT_APP_WEBSITE_URI} className="font-weight-bold ml-1 text-light">
                                    <FormattedMessage id="general.name.short" />
                                </a> &copy; {new Date().getFullYear()}
                            </div>
                        </Col>
                        <Col xl="6">
                            <Nav className="nav-footer justify-content-center justify-content-xl-end">
                                <NavItem>
                                    <a href={process.env.REACT_APP_WEBSITE_URI + "/blog"} className="nav-link"><FormattedMessage id="footer.blog" /></a>
                                </NavItem>

                                <NavItem>
                                    <a href={process.env.REACT_APP_WEBSITE_URI + "/privacy"} className="nav-link"><FormattedMessage id="footer.privacy" /></a>
                                </NavItem>

                                <NavItem>
                                    <a href={process.env.REACT_APP_WEBSITE_URI + "/cookies"} className="nav-link"><FormattedMessage id="footer.cookies" /></a>
                                </NavItem>

                                <NavItem>
                                    <a href={process.env.REACT_APP_WEBSITE_URI + "/about"} className="nav-link"><FormattedMessage id="footer.about" /></a>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default injectIntl(FooterLayout);
