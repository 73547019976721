import React, { Component } from "react";
import { Container, Row, Col, Button, Card, CardBody, CardHeader, Form } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import axios from "axios";

import { getAxiosError } from "../../utils/axiosUtils";
import CustomSelectInput from "../../components/common/customSelectInput";
import { authUpdateUserRedirect } from "../../store/actions/index";

class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            name: "",
            birthday: null,
            gender: null,
            civil: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeBirthDay = this.handleChangeBirthDay.bind(this);
        this.handleChangeGender = this.handleChangeGender.bind(this);
        this.handleChangeCivil = this.handleChangeCivil.bind(this);
    }

    componentDidMount() {
        document.body.classList.add("landing");
    }

    componentWillUnmount() {
        document.body.classList.remove("landing");
    }

    handleChange = async event => {
        console.log(event);
        const { target } = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [name]: value
        });
    };

    handleChangeBirthDay = async value => {
        await this.setState({
            birthday: value
        });
    };

    handleChangeGender = async value => {
        await this.setState({
            gender: value
        });
    };

    handleChangeCivil = async value => {
        await this.setState({
            civil: value
        });
    };

    submitHandler = event => {
        event.preventDefault();

        // console.log(this.state);
        const payload = this.state;
        axios
            .post(process.env.REACT_APP_API_URI + "/profile", payload)
            .then(response => {
                this.props.authUpdateUserRedirect(this.props.history, "/");
            })
            .catch(error => {
                console.log(getAxiosError(error));
            });
    };

    render() {
        const { intl } = this.props;

        const SELECT_GENDER = [
            { label: intl.formatMessage({ id: "view.profile.gender.female" }), value: "female", key: 0 },
            { label: intl.formatMessage({ id: "view.profile.gender.male" }), value: "male", key: 1 },
        ];

        const SELECT_CIVIL = [
            { label: intl.formatMessage({ id: "view.profile.civil.single" }), value: "single", key: 0 },
            { label: intl.formatMessage({ id: "view.profile.civil.married" }), value: "married", key: 1 },
            { label: intl.formatMessage({ id: "view.profile.civil.widowed" }), value: "widowed", key: 2 },
            { label: intl.formatMessage({ id: "view.profile.civil.divorced" }), value: "divorced", key: 3 },
            { label: intl.formatMessage({ id: "view.profile.civil.separated" }), value: "separated", key: 4 },
        ];

        return (
            <main >
                <section className="bg-primary-light">
                    <Container>
                        <Row className="py-5 pb-5">
                            <Col>
                                <Card className="shadow">
                                    <CardHeader className="bg-info text-white card-header-dashboard">
                                        {intl.formatMessage({ id: "view.profile.title" })}
                                    </CardHeader>
                                    <CardBody>
                                        <Form className="form" role="form" onSubmit={this.submitHandler} >
                                            <h6 className="heading-small text-muted mb-4">{intl.formatMessage({ id: "view.profile.user.title" })}</h6>
                                            <div className="pl-lg-4">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label" htmlFor="input-name">{intl.formatMessage({ id: "view.profile.name" })}</label>
                                                            <input type="text" id="input-name" name="name" className="form-control form-control-alternative" placeholder={intl.formatMessage({ id: "view.profile.name.ph" })} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label" htmlFor="input-email">{intl.formatMessage({ id: "view.profile.birthday" })}</label>
                                                            <DatePicker
                                                                selected={this.state.birthday}
                                                                onChange={this.handleChangeBirthDay}
                                                                placeholderText={intl.formatMessage({ id: "view.profile.birthday.ph" })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">{intl.formatMessage({ id: "view.profile.gender" })}</label>
                                                            <Select
                                                                components={{ Input: CustomSelectInput }}
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                name="gender"
                                                                placeholder={intl.formatMessage({ id: "view.profile.gender.ph" })}
                                                                onChange={this.handleChangeGender}
                                                                options={SELECT_GENDER}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">{intl.formatMessage({ id: "view.profile.civil" })}</label>
                                                            <Select
                                                                components={{ Input: CustomSelectInput }}
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                name="civil"
                                                                placeholder={intl.formatMessage({ id: "view.profile.civil.ph" })}
                                                                onChange={this.handleChangeCivil}
                                                                options={SELECT_CIVIL}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="my-4" />
                                            <h6 className="heading-small text-muted mb-4">{intl.formatMessage({ id: "view.profile.contact.title" })}</h6>
                                            <div className="pl-lg-4">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label" htmlFor="input-address">{intl.formatMessage({ id: "view.profile.address" })}</label>
                                                            <input id="input-address" className="form-control form-control-alternative" placeholder={intl.formatMessage({ id: "view.profile.address.ph" })} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label" htmlFor="input-city">{intl.formatMessage({ id: "view.profile.city" })}</label>
                                                            <input type="text" id="input-city" className="form-control form-control-alternative" placeholder={intl.formatMessage({ id: "view.profile.city.ph" })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label" htmlFor="input-country">{intl.formatMessage({ id: "view.profile.country" })}</label>
                                                            <input type="text" id="input-country" className="form-control form-control-alternative" placeholder={intl.formatMessage({ id: "view.profile.country.ph" })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label" htmlFor="input-postal-code">{intl.formatMessage({ id: "view.profile.postal" })}</label>
                                                            <input type="text" id="input-postal-code" className="form-control form-control-alternative" placeholder={intl.formatMessage({ id: "view.profile.postal.ph" })} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="my-4" />
                                            <h6 className="heading-small text-muted mb-4">{intl.formatMessage({ id: "view.profile.work.title" })}</h6>
                                            <div className="pl-lg-4">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label" htmlFor="input-occupation">{intl.formatMessage({ id: "view.profile.occupation" })}</label>
                                                            <input type="text" id="input-occupation" className="form-control form-control-alternative" placeholder={intl.formatMessage({ id: "view.profile.occupation.ph" })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label" htmlFor="input-lenght-occupation">{intl.formatMessage({ id: "view.profile.occupation.length" })}</label>
                                                            <input type="text" id="input-lenght-occupation" className="form-control form-control-alternative" placeholder={intl.formatMessage({ id: "view.profile.occupation.length.ph" })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label" htmlFor="input-work-pattern">{intl.formatMessage({ id: "view.profile.workp" })}</label>
                                                            <input type="text" id="input-work-pattern" className="form-control form-control-alternative" placeholder={intl.formatMessage({ id: "view.profile.workp.ph" })} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5 float-right">
                                                <Button color="link" className="btn-lg">{intl.formatMessage({ id: "view.profile.button.jump" })}</Button>
                                                <Button color="primary" className="btn-lg px-5 ml-2">{intl.formatMessage({ id: "view.profile.button.save" })}</Button>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        );
    }
}

export default injectIntl(connect(null, { authUpdateUserRedirect })(Profile));