import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { Navbar, Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import human from 'humanparser';

class NavbarLayout extends Component {

    render() {
        const { intl } = this.props;

        function showUser(props) {
            if (props.auth.isAuthenticated) {
                const user = props.auth.user;
                const username = human.parseName(user.name);

                return (
                    <div className="user d-inline-block">
                        <UncontrolledDropdown className="dropdown-menu-right">
                            <DropdownToggle className="p-0" color="empty">
                                <span className="name mr-1">{username.firstName}</span>
                                <span>
                                    <img alt="Avatar" src={user.avatar} />
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className="mt-2" right>
                                <NavLink to="/logout">
                                    <DropdownItem>
                                        {/* <div class="glyph-icon simple-icon-logout"></div> */}
                                        {intl.formatMessage({ id: "navbar.logout" })}
                                    </DropdownItem>
                                </NavLink>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <Navbar expand="md" className="navbar-top navbar-horizontal fixed-navbar">
                    <Container className="px-3 px-sm-2">
                        <NavLink className="navbar-logo" to="/">
                            <span className="logo d-block" />
                        </NavLink>
                        <div className="mr-auto">
                            <div className="header-icons d-inline-block align-middle">
                                <div className="position-relative d-inline-block">
                                    <UncontrolledDropdown className="dropdown-menu-left">
                                        <DropdownToggle className="header-icon" color="empty">
                                            <i className="simple-icon-grid" />
                                        </DropdownToggle>
                                        <DropdownMenu className="position-absolute mt-2" id="iconMenuDropdown" >
                                            <a href={process.env.REACT_APP_WEBSITE_URI + "/why"} className="icon-menu-item">
                                                <i className="iconsmind-Idea d-block" />{" "}
                                                {intl.formatMessage({ id: "navbar.why" })}
                                            </a>
                                            <a href={process.env.REACT_APP_WEBSITE_URI + "/services"} className="icon-menu-item">
                                                <i className="iconsmind-Optimization d-block" />{" "}
                                                {intl.formatMessage({ id: "navbar.services" })}
                                            </a>
                                            <a href={process.env.REACT_APP_WEBSITE_URI + "/pricing"} className="icon-menu-item">
                                                <i className="iconsmind-Financial d-block" />{" "}
                                                {intl.formatMessage({ id: "navbar.pricing" })}
                                            </a>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                        </div>
                        <div className="ml-auto">
                            {showUser(this.props)}
                        </div>
                    </Container>
                </Navbar>
            </Fragment >
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

export default injectIntl(connect(mapStateToProps)(NavbarLayout));
