import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, FormGroup, Label, CardHeader, Badge } from "reactstrap";
import { injectIntl } from "react-intl";
import { Formik, Form } from "formik";
import { NavLink } from "react-router-dom";

import * as yup from "yup";
import axios from "axios";

import moment from 'moment';

import { Upload, Icon, message, Modal, Button, Divider } from 'antd';
// import { Debug } from "../../../components/formik/formikDebug";
import MyDatePicker from "../../../components/formik/customAntDatePicker";
import MySelect from "../../../components/formik/customAntSelect";
import MyInput from "../../../components/formik/customAntInput";
const Dragger = Upload.Dragger;

// Human Systems
const systems = [
  { value: "auto", label: "Automatic" },
  { value: "general", label: "General" },
  { value: "circulatory", label: "Circulatory" },
  { value: "digestive", label: "Digestive" },
  { value: "endrocrine", label: "Endocrine" },
  { value: "exocrine", label: "Exocrine" },
  { value: "excretory", label: "Excretory" },
  { value: "lymphatic", label: "Lymphatic" },
  { value: "muscular", label: "Muscular" },
  { value: "nervous", label: "Nervous" },
  { value: "reprodutive", label: "Reprodutive" },
  { value: "respiratory", label: "Respiratory" },
  { value: "skeletal", label: "Skeletal" },
];

const initialValues = {
  system: "auto",
  type: "auto",
  date: moment(new Date(), 'DD/MM/YYYY'),
  description: "",
  attachments: [],
};

class Exams extends Component {
  state = {
    visible: false,
    fileList: [],
    exams: []
  }

  componentDidMount() {
    this.updateExamsList();
  }

  updateExamsList() {
    return axios.get(process.env.REACT_APP_API_URI + '/exams/').then(res => {
      this.setState({ exams: res.data.data });
    });
  }

  showModal = () => {
    this.setState({
      fileList: [],
      visible: true,
    });
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <Row className="mb-4">
            <Col>
              <Card>
                <CardHeader className="bg-info-light text-white card-header-dashboard">
                  My Exams
                  <div className="position-absolute card-top-buttons">
                    <Button type="secondary" onClick={this.showModal} icon="plus">Add Exam</Button>
                  </div>
                </CardHeader>
                <CardBody>
                  {this.state.exams.map((order, index) => {
                    return (
                      <div key={index} className="d-flex flex-row mb-3">
                        <NavLink to="/" className="d-block position-relative">
                          <div className={`align-self-center dashboard-thumbnail-letters bg-primary`}>
                            <i className={`iconsmind-Stethoscope`}></i>
                          </div>
                          <Badge key={index} className="position-absolute badge-top-left" color="secondary" pill >
                            {order.system}
                          </Badge>
                        </NavLink>

                        <div className="px-2 pt-1">
                          <NavLink to="/app/layouts/details">
                            <p className="list-item-heading mb-0">{order.type}</p>
                            <div className="pr-2">
                              <p className="text-muted mb-1 small">
                                {order.description}
                              </p>
                            </div>
                            <div className="text-primary text-small font-weight-medium d-none d-sm-block">
                              {moment(order.date).format("DD/MM/YYYY")}
                            </div>
                          </NavLink>
                        </div>
                      </div>
                    );
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container >

        <Modal
          title="Add Exam"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="650px"
          footer={null}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
              const form = new FormData();

              form.set("system", values.system);
              form.set("type", values.type);
              form.set("date", values.date);
              form.set("description", values.description);

              for (let i = 0; i <= this.state.fileList.length - 1; i++) {
                form.append("files", this.state.fileList[i], this.state.fileList[i].filename);
              }

              var self = this;
              axios.post(process.env.REACT_APP_API_URI + '/exams/new',
                form,
                { headers: { 'Content-Type': 'multipart/form-data' } }
              ).then((response) => {
                setSubmitting(false);
                message.success("Exame enviado com sucesso!");

                resetForm(initialValues);
                self.handleOk();
                self.updateExamsList();
                self.setState({ fileList: [] })
              }).catch((error) => {
                setSubmitting(false);
                message.error("Erro ao enviar exame!");
                console.log(error);
              });
            }}
            validationSchema={yup.object().shape({
              system: yup.string().nullable().required("Select the body system"),
              type: yup.string().nullable().required("Select the Exam Type"),
              date: yup.date().typeError("Invalid Date").required('Inform the date'),
              description: yup.string().nullable().required("Give us a description"),
            })}
            render={({ values, errors, touched, handleSubmit, handleChange, setFieldValue, handleBlur, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="input-system">System</Label>
                  <MySelect
                    id="input-system"
                    placeholder="Select the system..."
                    options={systems}
                    name="system"
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    value={values.system}
                  />
                </FormGroup>

                <FormGroup>
                  <Label className="form-control-label" htmlFor="input-type">Type</Label>
                  <MySelect
                    id="input-type"
                    placeholder="Select the type..."
                    options={systems}
                    name="type"
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    value={values.type}
                  />
                </FormGroup>

                <FormGroup>
                  <label className="form-control-label" htmlFor="input-data">Date</label>
                  <MyDatePicker
                    id="input-data"
                    placeholder="Selecione a Data"
                    format="DD/MM/YYYY"
                    name="date"
                    style={{ width: '100%' }}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    value={values.date}
                  />
                </FormGroup>

                <FormGroup>
                  <Label className="form-control-label" htmlFor="input-type">Description</Label>
                  <MyInput
                    id="input-type"
                    placeholder="Select the type..."
                    name="description"
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                </FormGroup>

                <FormGroup>
                  <label className="form-control-label">Attachments</label>
                  <Dragger
                    multiple={true}
                    onRemove={(file) => {
                      this.setState((state) => {
                        const index = state.fileList.indexOf(file);
                        const newFileList = state.fileList.slice();
                        newFileList.splice(index, 1);
                        return {
                          fileList: newFileList,
                        };
                      });
                      setFieldValue("attachments", this.state.fileList);
                    }}
                    beforeUpload={(file) => {
                      this.setState(state => ({
                        fileList: [...state.fileList, file],
                      }));
                      setFieldValue("attachments", this.state.fileList);
                      return false;
                    }}
                    fileList={this.state.fileList}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  </Dragger>
                </FormGroup>

                <Divider></Divider>

                <Row>
                  <Col>
                    <div className="mt-0 float-right">
                      <Button color="secondary" onClick={this.handleCancel} disabled={isSubmitting}>
                        Cancelar
                      </Button>
                      <Button type="primary" htmlType="submit" icon="save" className="ml-2 px-4 btn-primary" loading={isSubmitting} disabled={isSubmitting}>
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>

                {/* <Debug /> */}
              </Form>
            )} />
        </Modal>
      </React.Fragment>
    );
  }
}

export default injectIntl(Exams);
