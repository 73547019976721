import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utility';
import { defaultMenuType, subHiddenBreakpoint, menuHiddenBreakpoint } from '../../utils/defaultValues';

const initialState = {
    containerClassnames: defaultMenuType,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MENU_SET_CLASSNAMES:
            return updateObject(state, {
                containerClassnames: action.payload.containerClassnames,
                menuClickCount: action.payload.menuClickCount
            })
        case actionTypes.MENU_CLICK_MOBILE_MENU:
            return updateObject(state, {
                containerClassnames: action.payload.containerClassnames,
                menuClickCount: action.payload.menuClickCount
            })
        case actionTypes.MENU_CONTAINER_ADD_CLASSNAME:
            return updateObject(state, {
                containerClassnames: action.payload
            })
        case actionTypes.MENU_CHANGE_DEFAULT_CLASSES:
            return updateObject(state, {
                containerClassnames: action.payload
            })
        default: return { ...state };
    }
}
