import React, { Component, Fragment } from "react";
import { NavLink } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from "react-redux";

import { Container, Row, Col, Button, Card } from "reactstrap";

import * as actions from "../../store/actions/index";

class ConfirmAccount extends Component {

    componentDidMount() {
        document.body.classList.add("background");

        const values = this.props.match.params;
        if (values.user) {
            if (values.challenge) {
                this.props.onConfirmAccount(values.user, values.challenge, this.props.history);
            }
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("background");
    }

    render() {

        function IsLogged(props) {
            if (props.isAuthenticated) {
                return (
                    <div className="text-center">
                        <p className="h5">
                            <FormattedMessage id='view.confirm.logged.text' values={{ email: <b>{props.user.email}</b> }} />
                        </p>
                        <Button color="info" className="my-4 mt-5 py-3 btn-lg btn-block"><FormattedMessage id='view.confirm.logged.button' /></Button>
                    </div>
                )
            } else {
                return (
                    <div className="text-center">
                        <p className="h5">
                            <FormattedMessage id='view.confirm.not.logged.text' />
                        </p>
                        <NavLink to="/signin">
                            <Button color="info" className="my-4 mt-5 py-3 btn-lg btn-block">
                                <FormattedMessage id='view.confirm.not.logged.button' />
                            </Button>
                        </NavLink>
                    </div>
                )
            }
        }

        return (
            <Fragment>
                <div className="fixed-background bg-primary-light" />
                <main>
                    <Container>
                        <Row className="h-100">
                            <Col xs="12" md="10" className="mx-auto my-auto">
                                <Card className="auth-card">
                                    <div className="position-relative image-side bg-info-light">
                                        <h1 className="h2 font-weight-bold text-white mb-0 pb-0">
                                            <FormattedMessage id='view.confirm.title' />
                                        </h1>
                                    </div>
                                    <div className="form-side pt-5">
                                        {IsLogged(this.props)}
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </main>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onConfirmAccount: (user, challenge, history) => dispatch(actions.confirmAccount(user, challenge, history)),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfirmAccount));
